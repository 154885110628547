// axios
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://api.biosempresarial.uy',
  timeout: 300000,
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = store.getters['user/getToken']

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.token = `${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

// Add a response interceptor
axiosIns.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      store.commit('user/clear')

      // Redirect to login page
      router.push('/login')
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
