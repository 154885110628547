import { mdiAccountGroupOutline } from '@mdi/js'

export default [
  {
    subheader: 'Docente',
  },
  {
    title: 'Mis Grupos',
    to: { name: 'apps-teacher-mygroups' },
    icon: mdiAccountGroupOutline,
    resource: 'mygroups',
    action: 'nav',
  },
]
