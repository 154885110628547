<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="
            $store.dispatch('alertConfirm/HIDE', { result: false })
            show = false
          "
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="
            $store.dispatch('alertConfirm/HIDE', { result: true })
            show = false
          "
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from 'vue'
import store from '@/store'

export default {
  setup() {
    const title = ref('')
    const message = ref('')
    const show = ref(false)

    store.subscribe((mutation, state) => {
      if (mutation.type === 'alertConfirm/SHOW') {
        title.value = state.alertConfirm.title
        message.value = state.alertConfirm.message
        show.value = true
      }
    })

    return {
      title,
      message,
      show,
    }
  },
}
</script>
<style lang="scss">
.v-card__title {
  word-break: normal !important;
}
</style>
