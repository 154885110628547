import { mdiSchool } from '@mdi/js'

export default [
  {
    subheader: 'Alumno',
  },
  {
    title: 'Mis Cursos',
    to: { name: 'apps-mycourses' },
    icon: mdiSchool,
    resource: 'mycourses',
    action: 'nav',
  },
]
