import { mdiCalendarOutline, mdiHomeOutline } from '@mdi/js'
import administrador from './administrator'
import courses from './courses'
import student from './student'
import supervisor from './supervisor'
import teacher from './teacher'

// Array of sections
export default [
  {
    title: 'Inicio',
    icon: mdiHomeOutline,
    to: 'home',
    resource: 'home',
    action: 'nav',
  },
  {
    title: 'Cronograma',
    icon: mdiCalendarOutline,
    to: 'schedule',
    resource: 'schedule',
    action: 'nav',
  },
  ...administrador,
  ...courses,
  ...teacher,
  ...supervisor,
  ...student,
]
