import Deferred from './utils/Deferred'

let deferred

export default {
  namespaced: true,
  state: {
    title: '',
    message: '',
  },
  getters: {},
  mutations: {
    SHOW(state, { title, message }) {
      state.title = title
      state.message = message
    },
    HIDE(state, { result }) {
      if (!deferred) {
        return
      }
      deferred.resolve(result)
      deferred = null
      state.title = ''
      state.message = ''
    },
  },
  actions: {
    HIDE({ commit }, { result }) {
      commit('HIDE', { result })
    },
    CONFIRM({ dispatch, commit }, { title, message }) {
      if (deferred) {
        // Hide any existing dialogs
        dispatch('HIDE')
      }

      deferred = new Deferred()
      commit('SHOW', { title, message })

      return deferred.promise
    },
  },
}
