import '@/styles/styles.scss'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import relativeTime from 'dayjs/plugin/relativeTime'
import SocketIO from 'socket.io-client'
import Vue, { provide } from 'vue'
import VueSocketIO from 'vue-socket.io'
import App from './App.vue'

import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

export const vm = new Vue({
  router,
  store,
  //i18n,
  vuetify,
  setup() {
    dayjs.extend(relativeTime)
    provide('date', dayjs)

    const socket = new VueSocketIO({
      debug: false,
      connection: SocketIO(store.state.apidomain, { path: '/socket/' }), //options object is Optional
      vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_',
      },
    })

    provide('socket', socket)
    store.dispatch('setSocket', socket)
  },
  render: h => h(App),
}).$mount('#app')
