import { mdiAccountMultipleOutline } from '@mdi/js'

export default [
  {
    subheader: 'Cursos',
  },
  {
    title: 'Grupos',
    icon: mdiAccountMultipleOutline,
    to: 'apps-group-list',
    resource: 'group',
    action: 'nav',
  },
  {
    title: 'Vouchers',
    icon: mdiAccountMultipleOutline,
    to: 'apps-regular-list',
    resource: 'regular',
    action: 'nav',
  },
]
